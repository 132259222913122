export default function timeTransfer(time,number){
  time *= 1000;
  let date = new Date(time);
  let y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "0" + m : m;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let hh = date.getHours();
  hh = hh < 10 ? '0' + hh : hh;
  let mm = date.getMinutes();
  mm = mm < 10 ? "0" + mm : mm;
  let ss = date.getSeconds();
  ss = ss < 10 ? "0" + ss : ss;
  if(number){
    return y + '-' + m + '-' + d;
  }else{
    return y + '-' + m + '-' + d + " " + hh + ":" + mm + ":" + ss;
  }
}
