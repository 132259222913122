import axios from 'axios'
import baseUrl from "./baseUrl";
import router from '../router'
let userInfo = sessionStorage.getItem("userInfo");
let token = "";
if(userInfo){
  token = JSON.parse(userInfo).access_token;
  axios.interceptors.response.use((res) => {
    // 若是接口定义的错误
    if (res.status === 'xxx') {
      return Promise.reject('error message')
    }else if(res.data.message == '未知错误' || res.data.message == 'token失效'){
      router.push("/login")
    }
    return res
  }, (error) => {
    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
    alert(error.response.data.message);
    // 判断如何抛出错误
    return Promise.reject(error);
  });
}

export function request(url,params,number){
  let promise = new Promise(function(resolve,reject){
    let base = "";
    if(number){
      base = baseUrl;
    }else{
      base = baseUrl + "admin/"
    }

    axios.post(base + url,params,{headers:{Authorization:token}}).then(resolve).catch(reject);
  })
  return promise;
}

