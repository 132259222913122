export default [  //管理员
    {
      mainTitle: "项目列表",
      icon: "el-icon-document",
      active: "1",
      linkName: "/",
    },
    {
      mainTitle: "日志类型",
      icon: "el-icon-collection-tag",
      active: "2",
      linkName: "/log",
    },

/*    {
      mainTitle: "添加节点 ",
      icon: "el-icon-document",
      active: "12",
      linkName: "addNode",
    },
    {
      mainTitle: "添加子节点 ",
      icon: "el-icon-document",
      active: "13",
      linkName: "addSubNode",
    },*/
  ]

