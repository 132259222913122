<template>
  <div class="header flexRow justify-content-between align-item-center">
    <div class="title">日志后台管理系统</div>
    <div class="rightInfo flexRow align-item-center">
      <div class="user">欢迎登录！{{user}}</div>
      <div class="set">
        <el-dropdown @command="handleCommand">
              <span style="color:#fff;">
                <i class="el-icon-setting el-icon--right"></i> 设置
              </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">修改密码</el-dropdown-item>
            <el-dropdown-item command="2">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!--修改密码-->
    <el-dialog :visible.sync="showEdit" width="50%">
<!--      <p class="marginTop">新密码不少于8位，必须包含数字、英文字母以及特殊符号（~!@#$%^&*）</p>-->
      <el-form :mode="pwdForm" label-width="80px" class="marginTop">
        <el-form-item label="原密码">
          <el-input type="password" v-model="pwdForm.oldPwd" placeholder="请输入原密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input type="password" v-model="pwdForm.newPwd" placeholder="请输入新密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input type="password" v-model="pwdForm.confirmPwd" placeholder="请确认新密码" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changePwd">确认修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import md5 from 'js-md5'
export default {
  name: "MyHeader",
  data(){
    return{
      user:'',
      showEdit:false,
      pwdForm:{
        newPwd: '',
        oldPwd: '',
        confirmPwd: ''
      }
    }
  },
  mounted(){
    let userInfo = sessionStorage.getItem('userInfo')
    if(userInfo){
      this.user = JSON.parse(userInfo).account
    }
  },
  methods:{
    //设置
    handleCommand(command){
      if(command == 1){
        //修改密码
        this.showEdit = true;
        this.pwdForm = {
          newPwd: '',
          oldPwd: '',
          confirmPwd: ''
        }
      }else if(command == 2){
        //退出登录
        sessionStorage.removeItem("userInfo");
        this.$router.push("/login")
      }
    },

    //修改密码
    changePwd(){
      let newPwd = this.pwdForm.newPwd;
      let oldPwd = this.pwdForm.oldPwd;
      if(oldPwd == ""){
        this.$message.error("请输入旧密码");
        return;
      }
     /* let pwdReg = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*_])[\da-zA-Z~!@#$%^&*]{8,}$/;
      if(!pwdReg.test(newPwd)){
        this.$message.error("新密码不少于8位，必须包含数字、英文字母以及特殊符号（~!@#$%^&*）");
        return;
      }*/
      oldPwd = md5(oldPwd + 'log');
      if(newPwd == ""){
        this.$message.error("请输入新密码");
        return;
      }
      if(newPwd !== this.pwdForm.confirmPwd){
        this.$message.error("两次密码输入不一致");
        return;
      };
      newPwd = md5(newPwd + 'log');
      let params = {
        old_password:oldPwd,
        new_password:newPwd
      }
      this.$request("AdminUser/changePassword",params).then(res=>{
        if(res.data.code == 1){
          this.$message.success(res.data.message);
          sessionStorage.removeItem("userInfo");
          this.$router.push("/login")
        }else{
          this.$message.error(res.data.message);
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
.header{
  width:100%;
  height:70px;
  background: #409EFF;
  padding: 0 15px;
  box-sizing: border-box;
  color:#fff;
  .title{
    font-size: 20px;
  }
  .user{
    font-size:16px;
    margin-right:10px;
  }
  .set{
    cursor: pointer;
    font-size:16px;
  }
}
</style>