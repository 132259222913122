<template>
  <div class="leftMenu">
    <el-menu
        :default-active="menuActive"
        class="el-menu-vertical-demo"
        background-color="#545c64"
        text-color="#fff"
        router
        active-text-color="#ffd04b">
      <el-menu-item :index="item.linkName" v-for="(item ,index) in menus" :key="index">
        <i :class="item.icon"></i>
        <span slot="title">{{ item.mainTitle }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import menu from "../utils/menu";
export default {
  name: "LeftMenus",
  data(){
    return{
      menus:[],
    }
  },
  computed:{
    menuActive(){
      return sessionStorage.getItem('dActive') || '/'
    },
  },
  mounted() {
    this.menus = menu;
  },
  methods:{

  }
}
</script>
<style>
.el-submenu .el-menu-item{
  height:36px;
  line-height: 36px;
  padding:0;
  min-width: 140px;
  font-size:12px;
}
.el-menu-item, .el-submenu__title{
  height:44px;
  line-height: 44px;
}
.el-menu-item-group__title{
  padding:0 0 0 10px;
}
.el-menu{
  border-right:0;
}
.el-submenu__title{
  text-align: left;
}
.el-menu-item,.el-menu-item-group__title{
  display: flex;
  align-items: center;
  margin-top:10px;
}


</style>
<style scoped lang="less">
  .leftMenu{
    width:12vw;
    height:calc(100vh - 70px);
    background:#545c64;
    overflow: auto;
    border-top:1px solid #fff;
    box-sizing: border-box;
  }
</style>