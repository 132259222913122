<template>
  <div class="home">
    <my-header></my-header>
    <div class="flexRow">
      <left-menus></left-menus>
      <div class="content">
        <div class="subContent">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from "../components/MyHeader";
import LeftMenus from "../components/LeftMenus";
export default {
  name: 'Home',
  components: {
    MyHeader,
    LeftMenus
  },
  mounted(){
    let userInfo = sessionStorage.getItem('userInfo')
    if(!userInfo){
      this.$router.push('/login')
    }else{
      let refresh = sessionStorage.getItem('refresh');
      if(refresh){
        window.location.reload();
        sessionStorage.removeItem('refresh')
      }
    }
  },
  methods:{

  }
}
</script>
